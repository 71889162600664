<template>
  <div class="container">
    <div class="wishlist">
      <h1
        v-if="initialized && hasItemsInWishlist"
        class="text text--xs-header-3 text--xs-center text--md-left text--sm-header-2 text--sm-strong text--sm-mt-1 text--sm-mb-1"
      >
        Twoja ulubione zdjęcia
      </h1>

      <div
        :class="{
          'wishlist__content--loading': loading,
        }"
        class="wishlist__content"
      >
        <ImagesGrid v-if="initialized" :images="products" />

        <ImagesGridSkeleton
          v-if="!initialized && loading"
          :fixed-layout="true"
          :rows-count="2"
        />

        <p v-if="hasItemsInWishlist" class="wishlist__button">
          <button
            class="button button--small button--strong button--trash"
            @click="removeAllOnClick"
          >
            Wyczyść listę ulubionych zdjęć
          </button>
        </p>

        <div
          v-if="initialized && !loading && !hasItemsInWishlist"
          class="wishlist__empty"
        >
          <div class="info-box info-box--icon info-box--wishlist">
            <p class="info-box__text">
              Twoja lista ulubionych zdjęć jest pusta.
            </p>

            <div class="info-box__button">
              <router-link
                :to="{ name: 'categories-page' }"
                class="button button--strong"
              >
                Zobacz polecane kategorie
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.wishlist -->
  </div>
  <!-- /.container -->
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ImagesGrid from '../images-grid/images-grid.vue';
import ImagesGridSkeleton from '../images-grid/images-grid-skeleton.vue';

export default {
  name: 'Wishlist',
  components: {
    ImagesGrid,
    ImagesGridSkeleton,
  },
  props: {
    listId: {
      required: true,
      type: String,
    },
  },
  metaInfo: {
    title: 'Ulubione zdjęcia',
  },
  data: () => ({
    localInitialized: false,
  }),
  computed: {
    ...mapState('wishlist', {
      products: 'products',
      wishlistIds: 'ids',
      loading: 'loading',
      productsInitialized: 'productsInitialized',
    }),
    initialized() {
      return this.productsInitialized || this.localInitialized;
    },
    hasItemsInWishlist() {
      return this.products.length > 0;
    },
  },
  watch: {
    wishlistIds() {
      this.fetchProductsByIds(this.wishlistIds);
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    ...mapActions('wishlist', ['fetchProductsByIds', 'destroyWishlist']),

    async fetch() {
      try {
        await this.fetchProductsByIds(this.wishlistIds);
      } catch (e) {
        this.$bugsnag.notify(e);
      } finally {
        this.localInitialized = true;
      }
    },

    async removeAllOnClick() {
      await this.destroyWishlist();
      return this.$router.push({
        name: 'wishlist',
      });
    },
  },
};
</script>
